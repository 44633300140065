import './Dashboard.css';
import Modal  from './Modal';

const DashBord = () => {
    return (
        <div className="drawer drawer-mobile">
            <h2 className='align-left'>Welcome your DashBoard</h2>
        </div>
    );
};

export default DashBord;